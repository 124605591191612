import { createContext, useCallback, useContext, useState } from 'react'

type ErrorContextType = {
  statusCode: number
  errorCode: string
  setError: (statusCode: number, errorCode?: string) => void
}

const ErrorContext = createContext<ErrorContextType>({
  statusCode: 0,
  errorCode: '',
  setError: (_statusCode: number, _errorCode?: string) => {},
})

interface Props {
  statusCode: number
  errorCode: string
  setError: (statusCode: number, errorCode?: string) => void
}

export const ErrorContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  statusCode,
  errorCode,
  setError,
  children,
}) => (
  <ErrorContext.Provider value={{ statusCode, errorCode, setError }}>
    {children}
  </ErrorContext.Provider>
)

export const useError = () => useContext(ErrorContext)

export const useErrorFactory = (): ErrorContextType => {
  const [statusCode, setStatusCode] = useState(0)
  const [errorCode, setErrorCode] = useState('')
  const setError = useCallback((currentStatusCode: number, currentErrorCode: string) => {
    setStatusCode(currentStatusCode)

    if (currentErrorCode) {
      setErrorCode(currentErrorCode)
    }
  }, [])
  return {
    statusCode,
    errorCode,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    setError,
  }
}
