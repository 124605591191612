/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AddResponseGuestCompanionCustomResponseRequest } from './add-response-guest-companion-custom-response-request'

/**
 *
 * @export
 * @interface AddResponseGuestCompanionRequest
 */
export interface AddResponseGuestCompanionRequest {
  /**
   *
   * @type {string}
   * @memberof AddResponseGuestCompanionRequest
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof AddResponseGuestCompanionRequest
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof AddResponseGuestCompanionRequest
   */
  lastNameKana: string
  /**
   *
   * @type {string}
   * @memberof AddResponseGuestCompanionRequest
   */
  firstNameKana: string
  /**
   *
   * @type {string}
   * @memberof AddResponseGuestCompanionRequest
   */
  sex?: AddResponseGuestCompanionRequestSex
  /**
   *
   * @type {string}
   * @memberof AddResponseGuestCompanionRequest
   */
  optionalSex?: string
  /**
   *
   * @type {Array<AddResponseGuestCompanionCustomResponseRequest>}
   * @memberof AddResponseGuestCompanionRequest
   */
  customResponses: Array<AddResponseGuestCompanionCustomResponseRequest>
  /**
   *
   * @type {string}
   * @memberof AddResponseGuestCompanionRequest
   */
  message?: string
}

/**
 * @export
 * @enum {string}
 */
export enum AddResponseGuestCompanionRequestSex {
  male = 'MALE',
  female = 'FEMALE',
  noAnswer = 'NO_ANSWER',
  free = 'FREE',
}
