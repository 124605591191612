import { ErrorHead } from '@/components/common/commonHead'
import { RoundMBorderButton } from '@/components/cs/button'
import { ErrorLayout, styles } from '@/components/cs/errorLayout'
import { useConfig } from '@/context/config'
import { isEnabledFixButtonForCommonErrorPage } from '@/utils/common/featureToggle'
import { useRouter } from 'next/router'

export default function NetworkError() {
  const { config } = useConfig()
  const router = useRouter()

  return (
    <ErrorLayout>
      <ErrorHead />
      <h1 css={styles.head}>データの取得に失敗しました。</h1>
      <p css={styles.text}>
        通信環境の良い場所で
        <br />
        再読み込みしてください。
      </p>
      {isEnabledFixButtonForCommonErrorPage(config.featureToggle) ? (
        <RoundMBorderButton
          label="再読み込み"
          css={styles.button}
          onClick={() => router.reload()}
        />
      ) : (
        <button css={styles.deprecatedButton} onClick={() => router.reload()}>
          再読み込み
        </button>
      )}
    </ErrorLayout>
  )
}
