/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GuestBucketSummary } from './guest-bucket-summary'
import { GuestListUpAnalyticsDataResponse } from './guest-list-up-analytics-data-response'

/**
 *
 * @export
 * @interface GetGuestListSummaryResponse
 */
export interface GetGuestListSummaryResponse {
  /**
   *
   * @type {string}
   * @memberof GetGuestListSummaryResponse
   */
  guestListUUID: string
  /**
   *
   * @type {number}
   * @memberof GetGuestListSummaryResponse
   */
  expectedGuestCount: number
  /**
   *
   * @type {number}
   * @memberof GetGuestListSummaryResponse
   */
  numberOfToBeInvited: number
  /**
   *
   * @type {number}
   * @memberof GetGuestListSummaryResponse
   */
  totalNumberOfInput: number
  /**
   *
   * @type {number}
   * @memberof GetGuestListSummaryResponse
   */
  expectedAbsenceGuestCount: number
  /**
   *
   * @type {number}
   * @memberof GetGuestListSummaryResponse
   */
  approximateGuestCountToInvite: number
  /**
   *
   * @type {boolean}
   * @memberof GetGuestListSummaryResponse
   */
  hasReachedApproximateGuestCount: boolean
  /**
   *
   * @type {number}
   * @memberof GetGuestListSummaryResponse
   */
  insufficientNumberOfGuests?: number
  /**
   *
   * @type {boolean}
   * @memberof GetGuestListSummaryResponse
   */
  partnerInvitationCompleted: boolean
  /**
   *
   * @type {string}
   * @memberof GetGuestListSummaryResponse
   */
  appointmentDate: string
  /**
   *
   * @type {GuestBucketSummary}
   * @memberof GetGuestListSummaryResponse
   */
  firstGuestBucketSummary: GuestBucketSummary
  /**
   *
   * @type {GuestBucketSummary}
   * @memberof GetGuestListSummaryResponse
   */
  secondGuestBucketSummary: GuestBucketSummary
  /**
   *
   * @type {string}
   * @memberof GetGuestListSummaryResponse
   */
  submitStatus: GetGuestListSummaryResponseSubmitStatus
  /**
   *
   * @type {GuestListUpAnalyticsDataResponse}
   * @memberof GetGuestListSummaryResponse
   */
  guestListUpAnalyticsDataResponse: GuestListUpAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum GetGuestListSummaryResponseSubmitStatus {
  canNotSubmit = 'CAN_NOT_SUBMIT',
  readyToSubmit = 'READY_TO_SUBMIT',
  hasSubmitted = 'HAS_SUBMITTED',
}
