/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AddResponseCustomResponseRequest } from './add-response-custom-response-request'
import { AddResponseGuestCompanionRequest } from './add-response-guest-companion-request'

/**
 *
 * @export
 * @interface AddResponseRequest
 */
export interface AddResponseRequest {
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  invitationUUID: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  attendance: AddResponseRequestAttendance
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  lastNameKana: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  firstNameKana: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  emailAddress: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  whichGuest?: AddResponseRequestWhichGuest
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  sex?: AddResponseRequestSex
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  optionalSex?: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  postCode?: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  prefecture?: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  address?: string
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  tel?: string
  /**
   *
   * @type {Array<AddResponseCustomResponseRequest>}
   * @memberof AddResponseRequest
   */
  customResponses: Array<AddResponseCustomResponseRequest>
  /**
   *
   * @type {string}
   * @memberof AddResponseRequest
   */
  message?: string
  /**
   *
   * @type {Array<AddResponseGuestCompanionRequest>}
   * @memberof AddResponseRequest
   */
  guestCompanions: Array<AddResponseGuestCompanionRequest>
}

/**
 * @export
 * @enum {string}
 */
export enum AddResponseRequestAttendance {
  attendance = 'ATTENDANCE',
  absence = 'ABSENCE',
}
/**
 * @export
 * @enum {string}
 */
export enum AddResponseRequestWhichGuest {
  shinroGuest = 'SHINRO_GUEST',
  shinpuGuest = 'SHINPU_GUEST',
}
/**
 * @export
 * @enum {string}
 */
export enum AddResponseRequestSex {
  male = 'MALE',
  female = 'FEMALE',
  noAnswer = 'NO_ANSWER',
  free = 'FREE',
}
