import { GetClientAuthUserResponse } from '@/data/gen'
import React, { createContext, useContext, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
type ClientUser = GetClientAuthUserResponse

type ClientUserContextType = {
  user: ClientUser
  setUser: (_: ClientUser) => void
}

const ClientUserContext = createContext<ClientUserContextType>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  user: null,
  setUser: (_: ClientUser) => {},
})

interface Props {
  user: ClientUser
  setUser: (_: ClientUser) => void
}

export const ClientUserContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  setUser,
  children,
}) => <ClientUserContext.Provider value={{ user, setUser }}>{children}</ClientUserContext.Provider>

export const useClientUserContext = () => useContext(ClientUserContext)

export const useClientUserFactory = (): ClientUserContextType => {
  const [user, setUser] = useState(null)
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    user,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    setUser,
  }
}
