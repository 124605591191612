export const FEATURE_TOGGLE_TYPE = {
  prd: 'production',
  stg: 'staging',
  dev: 'development',
  demo: 'demo',
} as const

export type Feature = typeof FEATURE_TOGGLE_TYPE[keyof typeof FEATURE_TOGGLE_TYPE]

export const isDevelopment = (feature: Feature) => feature === FEATURE_TOGGLE_TYPE.dev

// デモ環境向けの機能の出しわけのフラグ
// デモ環境向けだとしても開発環境では、機能が全て見える状態であった方が良いため開発環境でも利用できるようにしている
export const isDevelopmentOrDemo = (feature: Feature) =>
  feature === FEATURE_TOGGLE_TYPE.dev || feature === FEATURE_TOGGLE_TYPE.demo

export const isDemo = (feature: Feature) => feature === FEATURE_TOGGLE_TYPE.demo

/**
 * XYSAAS-7855 共通エラー画面のボタンを共通ボタンにする対応の出しわけフラグ
 * dev環境の場合trueになる
 */
export const isEnabledFixButtonForCommonErrorPage = (feature: Feature) => isDevelopment(feature)

/**
 * XYSAAS-8076 ジェンダー対応の出し分けフラグ
 * dev環境の場合trueになる
 */
export const isEnabledGenderResponsive = (feature: Feature) => isDevelopment(feature)
